import { AfterPurchaseStep, SUBSCRIPTION_NAMES } from "./screens";

export const NUMEROLOGY_ANALYSIS = 'numerology_analysis';
export const PALMISTRY_GUIDE = 'palmistry_guide';
export const ASTROLOGER_CONSULTATION = 'astrologer_consultation';
export const BIRTH_CHART = 'birth_chart';
export const COMPATIBILITY = 'compatibility';
export const NUMEROLOGY_ANALYSIS_PALMISTRY_GUIDE = 'numerology_analysis+palmistry_guide';
export const COMPATIBILITY_BIRTH_CHART_ULTRA = 'birth_chart+compatibility';

export type OfferProductId = 'numerology_analysis'
  | 'palmistry_guide'
  | 'birth_chart+compatibility'
  | 'birth_chart'
  | 'compatibility'
  | 'numerology_analysis+palmistry_guide'


export const OFFERS_BY_PAGES: { [k in AfterPurchaseStep | 'MAIN']?: OfferProductId | OfferProductId[] } = {
  [SUBSCRIPTION_NAMES.COMPATIBILITY]: COMPATIBILITY,
  [SUBSCRIPTION_NAMES.BIRTH_CHART]: BIRTH_CHART,
  [SUBSCRIPTION_NAMES.NUMEROLOGY_ANALYSIS]: NUMEROLOGY_ANALYSIS,
  [SUBSCRIPTION_NAMES.GUIDES]: [NUMEROLOGY_ANALYSIS, PALMISTRY_GUIDE, NUMEROLOGY_ANALYSIS_PALMISTRY_GUIDE],
  [SUBSCRIPTION_NAMES.COMPATIBILITY_BIRTH_CHART_ULTRA]: [COMPATIBILITY, BIRTH_CHART, COMPATIBILITY_BIRTH_CHART_ULTRA],
}
import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AdvisorCardData } from 'src/components/advisor-cards-swiper/types';

export type ShippingAddress = {
  email: string,
  name: string,
  last_name: string,
  country: {
    value: string,
    label: string,
    state: boolean,
  },
  state: {
    value: string,
    label: string,
  },
  postal_code: string,
  city: string,
  address: string,
  phone: string,
}
type ShippingMethod = {
  id: string,
  deliverier: string,
  shipping_level: string,
  delivery_interval: string,
  price: string,
  currency: string
}

export interface ProfileState {
  authorized: boolean;
  linkAuthResult: any;

  email: string;

  name: string;
  gender: string | null;
  age: string | null;
  relStatus: string | null;
  relyOn: string;

  handImg: any;
  handImgDims: any;
  handKeyPoints: any;
  handBasePoint: any;
  handLines: any;
  handResults: any;
  palmReport: any;
  originalLines: any;

  astologerTimeBalance: number | null;
  birthDate: string | null; // YYYY-MM-DD
  birthTime: string | null; // HH:mm
  birthPlace: { label: string, lat: string, lon: string } | null;
  approximateLocation: {
    lat: string,
    lon: string,
    country_code: string,
    label: string,
  } | null;
  color: { color: string, text: string } | null;
  element: { image: any, text: string } | null;
  futureGoals: { id?: string, icon: string, text: string }[] | null;
  direction: string | null;
  goals: string[] | null;
  is_unsubscriber: boolean | null;
  lang: string;
  soulmateInfo: {
    love: number;
    marriage: number;
    trust: number;
    teamwork: number;
    communication: number;
  };

  shippingAddress: ShippingAddress;
  shippingMethod: ShippingMethod;

  advisorsData: AdvisorCardData[];
  selectedAdvisorId: number | null;
}

const initialState: ProfileState = {
  authorized: false,
  linkAuthResult: null,

  email: '',

  soulmateInfo: {
    love: 88,
    marriage: 63,
    trust: 81,
    teamwork: 70,
    communication: 76,
  },

  name: '',
  gender: null,
  age: null,
  relStatus: null,
  relyOn: '',

  handImg: null,
  handImgDims: null,
  handKeyPoints: null,
  handBasePoint: null,
  handLines: null,
  handResults: null,
  palmReport: null,
  originalLines: null,

  birthDate: null, // YYYY-MM-DD
  birthTime: null, // HH:mm
  birthPlace: null, // { label: string, lat: string, lon: string }

  approximateLocation: null,

  color: null, // { color: string, text: string }
  element: null, // { image: obj, text: string }
  futureGoals: null, // [{ icon: string, text: string}]

  direction: null,
  goals: null,
  astologerTimeBalance: null,
  lang: i18next.language,

  is_unsubscriber: null,

  shippingAddress: {
    email: '',
    name: '',
    last_name: '',
    country: {
      value: '',
      label: '',
      state: false,
    },
    state: {
      value: '',
      label: '',
    },
    postal_code: '',
    city: '',
    address: '',
    phone: '',
  },
  shippingMethod: {
    id: '',
    deliverier: '',
    shipping_level: '',
    delivery_interval: '',
    price: '',
    currency: ''
  },
  advisorsData: [],
  selectedAdvisorId: null,
};


const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload || initialState.email;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setGender: (state, action: PayloadAction<string | null>) => {
      state.gender = action.payload
    },
    setAge: (state, action: PayloadAction<string>) => {
      state.age = action.payload
    },
    setRelStatus: (state, action: PayloadAction<string | null>) => {
      state.relStatus = action.payload
    },
    setRelyOn: (state, action: PayloadAction<string>) => {
      state.relyOn = action.payload
    },
    setPalmReport: (state, action: PayloadAction<any>) => {
      state.palmReport = action.payload
    },
    setHandImg: (state, action: PayloadAction<any>) => {
      state.handImg = action.payload.img;
      state.handImgDims = { width: action.payload.width, height: action.payload.height, ratio: action.payload.ratio, };
      state.handKeyPoints = action.payload.keypoints;
      state.handBasePoint = action.payload.basePoint;
      state.handLines = action.payload.lines;
      state.originalLines = action.payload.originalLines;
    },
    setHandResults: (state, action: PayloadAction<any>) => {
      state.handResults = action.payload;
    },
    setAstologerTimeBalance: (state, action: PayloadAction<any>) => {
      state.astologerTimeBalance = action.payload;
    },
    setBirthDate: (state, action: PayloadAction<any>) => {
      state.birthDate = action.payload;
    },
    setBirthTime: (state, action: PayloadAction<any>) => {
      state.birthTime = action.payload;
    },
    setBirthPlace: (state, action: PayloadAction<any>) => {
      state.birthPlace = action.payload;
    },
    setApproximateLocation: (state, action: PayloadAction<{
      lat: string,
      lon: string,
      country_code: string,
      label: string,
    }>) => {
      state.approximateLocation = action.payload;
    },
    setColor: (state, action: PayloadAction<any>) => {
      state.color = action.payload;
    },
    setElement: (state, action: PayloadAction<any>) => {
      state.element = action.payload;
    },
    setFutureGoals: (state, action: PayloadAction<any>) => {
      state.futureGoals = action.payload;
    },
    setGoals: (state, action: PayloadAction<any>) => {
      state.goals = action.payload;
    },
    setLang: (state, action: PayloadAction<any>) => {
      state.lang = action.payload;
    },
    setIsUnsubscriber: (state, action: PayloadAction<any>) => {
      state.is_unsubscriber = action.payload;
    },
    setAuthorized: (state) => {
      state.authorized = true;
    },
    setLinkAuthResult: (state, action: PayloadAction<any>) => {
      state.linkAuthResult = action.payload;
    },
    setAstrologerTimeBalance: (state, action: PayloadAction<any>) => {
      state.astologerTimeBalance = action.payload;
    },
    setCriticalDirection: (state, action: PayloadAction<any>) => {
      state.direction = action.payload;
      state.goals = state.direction === action.payload ? state.goals : initialState.goals;
    },
    setDesirableGoals: (state, action: PayloadAction<any>) => {
      state.goals = action.payload;
    },
    setShippingAddress: (state, action: PayloadAction<ShippingAddress>) => {
      state.shippingAddress = action.payload;
    },
    setShippingMethod: (state, action: PayloadAction<ShippingMethod>) => {
      state.shippingMethod = action.payload;
    },
    generateSoulmateInfo: (state) => {
      const soulmateInfo = {
        love: randomPrediction(),
        marriage: randomPrediction(),
        trust: randomPrediction(),
        teamwork: randomPrediction(),
        communication: randomPrediction(),
      }
      state.soulmateInfo = soulmateInfo;
    },
    setAdvisorsData: (state, action: PayloadAction<AdvisorCardData[]>) => {
      state.advisorsData = action.payload;
      state.selectedAdvisorId = action.payload[0].astrologer_id;
    },
    setSelectedAdvisorId: (state, action: PayloadAction<number>) => {
      state.selectedAdvisorId = action.payload;
    }
  }
})

export const blacklist = ['authorized', 'linkAuthResult'];

export { slice as profileSlice };

export const randomPrediction = () => {
  return Math.round(65 + (95 - 65) * Math.random());
}

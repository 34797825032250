import { BaseOfferProductTypes } from "@web-solutions/core/constants/remote-config";
import { LString } from "@web-solutions/module-localization"

import type { ExtendedWithMultiCurrencies } from '@web-solutions/core/interfaces/currencies'

export type ValidRoute = "REASON" | "PLANS" | "BENEFITS" | "PROGRESS" | "OFFER_FREE_PERIOD" | "FAREWELL_OFFER" | "OFFER_CHEAP_PRODUCT" | "SURVEY";
export type CustomFlow = Array<ValidRoute>;

export interface RemoteConfig {
  manageWithReviewStep: {
    enabled: boolean,
    showSkip?: boolean,
  },
  isShortManageVariant: boolean,
  manageSurvey: {
    link: {
      trial: string,
      active: string,
      terminate: string,
    },
    enableClose: boolean,
    customFlow: CustomFlow,
  },
  mngDontMissPage: {
    enabled: boolean;
  },
  mngFreeGiftCancelPage: {
    enabled: boolean;
    title: LString;
    subtitle: LString;
  },
  mngSubEmailCancelPage: {
    enabled: boolean;
    title: LString;
    subtitle: LString;
    isSimpleMode: boolean;
    isEnableLang: boolean;
  },
  mngSubPlansPage: {
    title: LString,
    buttonText: LString,
  },
  mngSubMainPage: {
    currentPlanPrice: string,
    changePlanButtonText: LString,
    planDetailsEnabled: boolean,
    showMainContent?: boolean,
    showBackBtn?: boolean,
    updatingDelay?: number
  },
  mngSubReasonPage: {
    enabled: boolean
    changeMindOff: boolean
    changeMindSelected: boolean
  }
  mngSubBenefitsPage: {
    enabled: boolean,
    fixedButton: boolean,
    fixedLink: boolean,
  }
  mngSubCancelSuccessPage: {
    mode: 'default' | 'without_logo'
    closeEnabled: boolean
  },
  mngSubOfferFreePeriodPage: ExtendedWithMultiCurrencies<{
    enabled: boolean,
    exclusiveOfferEnabled: boolean,
    fixedLink: boolean,
    fixedButton: boolean
  }, {
    subscriptionDescription: LString,
    productId: string,
  }, false>
  mngSubFarewellOfferPage: {
    enabled: boolean,
    singleProduct: Omit<BaseOfferProductTypes<string>, 'id'>,
    ultraProduct: Omit<BaseOfferProductTypes<string>, 'id'>,
    acceptBtnTitle?: LString,
    declineBtnTitle?: LString,
    type: 'reports' | 'list',
  },
  mngSubOfferCheapProductPage: ExtendedWithMultiCurrencies<{
    enabled: boolean,
    backEnabled: boolean,
    discountAmount: number,
    subtitle: LString
  }, {
    price: LString,
    productId: string,
  }, false>,
  mngSubTerminatePage: {
    fixedLink: boolean,
    fixedButton: boolean,
    enabled: boolean,
  },
  mngProgressPage: {
    enabled: boolean,
    fixedLink: boolean,
    fixedButton: boolean,
  }
}

export const INITIAL_CONFIG: RemoteConfig = {
  manageWithReviewStep: {
    enabled: true,
    showSkip: true,
  },
  isShortManageVariant: false,
  mngDontMissPage: {
    enabled: false
  },
  manageSurvey: {
    link: {
      trial: '',
      active: '',
      terminate: '',
    },
    enableClose: false,
    customFlow: [],
  },
  mngSubEmailCancelPage: {
    enabled: false,
    title: '',
    subtitle: '',
    isSimpleMode: true,
    isEnableLang: true,
  },
  mngFreeGiftCancelPage: {
    enabled: false,
    title: '',
    subtitle: '',
  },
  mngSubPlansPage: {
    title: '',
    buttonText: '',
  },
  mngSubMainPage: {
    currentPlanPrice: '{priceText}',
    changePlanButtonText: '',
    planDetailsEnabled: true,
    showMainContent: true,
    showBackBtn: true,
    updatingDelay: 2,
  },
  mngSubReasonPage: {
    enabled: true,
    changeMindOff: false,
    changeMindSelected: false,
  },
  mngSubBenefitsPage: {
    enabled: false,
    fixedButton: false,
    fixedLink: false,
  },
  mngSubCancelSuccessPage: {
    mode: 'default',
    closeEnabled: true,
  },
  mngSubOfferFreePeriodPage: {
    enabled: false,
    exclusiveOfferEnabled: true,
    fixedLink: false,
    fixedButton: false,
    USD: {
      subscriptionDescription: '',
      productId: '',
    },
    EUR: {
      subscriptionDescription: '',
      productId: '',
    },
    CAD: {
      subscriptionDescription: '',
      productId: '',
    },
    AUD: {
      subscriptionDescription: '',
      productId: '',
    },
    BRL: {
      subscriptionDescription: '',
      productId: '',
    },
    GBP: {
      subscriptionDescription: '',
      productId: '',
    }
  },
  mngSubFarewellOfferPage: {
    enabled: false,
    singleProduct: {
      title: '',
      subTitle: '',
      notActivePrice: '',
      activePrice: '',
      price: 1,
      discount: '',
      currency: 'USD',
    },
    ultraProduct: {
      title: '',
      subTitle: '',
      notActivePrice: '',
      activePrice: '',
      price: 1,
      discount: '',
      currency: 'USD',
    },
    type: 'list',
  },
  mngSubOfferCheapProductPage: {
    enabled: false,
    backEnabled: true,
    discountAmount: 76,
    subtitle: '',
    USD: {
      price: '',
      productId: '',
    },
    EUR: {
      price: '',
      productId: '',
    },
    CAD: {
      price: '',
      productId: '',
    },
    AUD: {
      price: '',
      productId: '',
    },
    BRL: {
      price: '',
      productId: '',
    },
    GBP: {
      price: '',
      productId: '',
    }
  },
  mngSubTerminatePage: {
    fixedLink: false,
    fixedButton: false,
    enabled: false
  },
  mngProgressPage: {
    enabled: false,
    fixedLink: false,
    fixedButton: false,
  }
};
